import { Text } from '@mantine/core';
import { FC } from 'react';

export const NotFound: FC = () => {
  return (
    <>
      <Text>404</Text>
      <Text>Page Not Found</Text>
    </>
  );
};
