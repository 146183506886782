/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';

import World from './World';

//const svg = useRef<SVGSVGElement>;
const MapSection: FC = () => {
  return (
    <>
      <World />
    </>
  );
};

export default MapSection;
